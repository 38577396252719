import React from 'react';
import 'sweetalert2/src/sweetalert2.scss';
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
  } from 'recharts';

const DolChart = ({ data }) => {

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className="custom-tooltip" style={{backgroundColor: "white", padding: "10px", outline: "1px solid gray"}}>
              <p className="label">{`${label}`}</p>
              <p className="label">{`Liking Score : ${payload[0].value}`}</p>
              { payload[0].value > 0 &&
                <p className="desc">Liking: Positive</p>
              }
                { payload[0].value < 0 &&
                <p className="desc">Liking: Negative</p>
              }
              {/*<p className="desc">Range: 0-0.17%</p>*/}
              <p className="desc">Note: null</p>
            </div>
          );
        }
      
        return null;
    };

    if (!data) return <div>loading</div>

    return (
      <ResponsiveContainer width='100%' aspect={4.0/3.0}>
        <BarChart
        width={1000}
        height={500}
        data={data}
        margin={{
            top: 5,
            right: 50,
            left: 20,
            bottom: 200,
        }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" angle={90} tickMargin={80} />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <ReferenceLine y={0} stroke="#000" />

            <Bar dataKey='LikingScore'>
            {data.map((datum, index) => (
                <Cell 
                    key={`cell-${index}`}
                    fill={ datum.LikingScore > 0 ? '#1976d2' : 'red'}
                />
            ))}
            </Bar>`
        </BarChart>
        </ResponsiveContainer>
    )
}

export default DolChart;